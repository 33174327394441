<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button> </p>
            </div>
        </div>

        <div class="form-list">
            <el-form :model="dataSource" ref="_sysLogForm">
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        登录名：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="CreatedUserName">
                            <el-input v-model="dataSource.CreatedUserName" :disabled="true" :maxlength="30" :minlength="2"
                                placeholder="登录名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">
                        操作人IP：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="OperationIP">
                            <el-input v-model="dataSource.OperationIP" :disabled="true" :maxlength="30" :minlength="2"
                                placeholder="操作人IP"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        操作方法：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="OperationAction">
                            <el-input v-model="dataSource.OperationAction" :disabled="true" :maxlength="30" :minlength="2"
                                placeholder="操作"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">创建时间：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="CreatedTime">
                            <el-input v-model="dataSource.CreatedTime" :disabled="true" :maxlength="30" :minlength="2"
                                placeholder="创建时间"></el-input>
                        </el-form-item>
                    </el-col>

                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        方法类型：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="MethodType">
                            <el-input :disabled="true" v-model="dataSource.MethodType"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">
                        操作类型：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="OperationType">
                            <el-select :disabled="true" v-model="dataSource.OperationType" placeholder="请选择">
                                <el-option v-for="item in options" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                            <!-- <el-input :disabled="true" v-model="dataSource.OperationType"></el-input> -->
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        请求地址：
                    </el-col>
                    <el-col :span="17">
                        <el-form-item prop="OperationUrl">
                            <el-input :disabled="true" v-model="dataSource.OperationUrl"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        请求内容：
                    </el-col>
                    <el-col :span="17">
                        <el-form-item prop="requestContent">
                            <el-input type="textarea" :rows="10" v-model="requestContent"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        返回结果：
                    </el-col>
                    <el-col :span="17">
                        <el-form-item prop="responseContent">
                            <el-input type="textarea" :rows="20" v-model="responseContent"></el-input>
                        </el-form-item>
                        <p style="text-align: right;">
                            <!-- <span> {{$t(`报文转换`)}}：</span> -->
                            <el-link type="primary" @click="showSrc">原文</el-link> &nbsp;
                            <el-link type="primary" @click="jonsDeal">格式化</el-link> &nbsp;
                            <el-link type="primary" @click="urldecode">编码转义</el-link>
                        </p>
                    </el-col>
                </el-form-item>



            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            options: [{
                value: "100",
                label: '登录'
            }, {
                value: "200",
                label: '操作'
            }, {
                value: "300",
                label: '注销'
            }
            ],
            requestContent: null,
            responseContent: null,
            isJsonBuef: true,
            isEncodeBuef: true,
        }
    },
    props: {
        config: {
            isDetailDisplay: false,
        },
        dataSource: {}
    },
    methods: {

        showSrc() {
            var _this = this;
            _this.requestContent = _this.dataSource.RequestParam;
            _this.responseContent = _this.dataSource.OperationDesc;
            _this.isJsonBuef = true;
            _this.isEncodeBuef = true;
        },
        jonsDeal() {
            var _this = this;
            if (_this.isJsonBuef) {
                _this.isJsonBuef = false;
                try {
                    var jsonObj = JSON.parse(_this.requestContent || '');
                    _this.requestContent = JSON.stringify(jsonObj, null, 4);
                } catch (e) {
                    _this.requestContent = _this.requestContent;
                }
                try {
                    jsonObj = JSON.parse(_this.responseContent || '');
                    _this.responseContent = JSON.stringify(jsonObj, null, 4);

                } catch (e) {
                    _this.responseContent = _this.responseContent;
                }

            }
            else {
                // _this.isJsonBuef = true;
                // _this.requestContent = _this.detailSource.RequestContent;
                // _this.responseContent = _this.detailSource.ResponseContent;
            }
        },
        urldecode() {
            var _this = this;
            if (_this.isEncodeBuef) {
                _this.isEncodeBuef = false;
                _this.requestContent = decodeURIComponent(_this.requestContent);
                _this.responseContent = decodeURIComponent(_this.responseContent);
            }

        }
    }
}
</script>
